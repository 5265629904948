<template>
  <div class="unbreakable-pass-view">
    <!-- image section -->
    <div class="pass-image">
      <dir class="back-button" @click="$router.push({ name: 'benefits' })">
        <i class="material-icons">keyboard_arrow_left</i>
        <span>{{ $t("back") }}</span>
      </dir>
      <div class="background-gradient"></div>
      <template>
        <img
          src="@/assets/upass_rare_background.png"
          alt="rare!!!"
          class="rare-image"
          v-if="isRarePass"
        />
        <img
          v-else
          src="@/assets/upass_background.png"
          alt=""
          class="regular-image"
        />
      </template>
      <img src="@/assets/upass_text_title.png" alt="" class="image-title" />
    </div>
    <!-- search section -->
    <div class="search-section">
      <div class="mem-subtitle">{{ $t("booking_your_tickets") }}</div>
      <!-- selectors -->
      <div class="search-selectors">
        <!-- brand -->
        <v-menu transition="slide-y-transition" max-height="300" bottom right>
          <template v-slot:activator="{ on, attrs }">
            <div class="search-selector" v-bind="attrs" v-on="on">
              <div class="selector-input">
                <span class="selector-label">{{ $t("brand") }}</span>
                <template>
                  <div class="selector-value" v-if="selectedBrand">
                    {{ selectedBrand }}
                  </div>
                  <div class="selector-value empty-value" v-else>
                    {{ $t("select") }}
                  </div>
                </template>
              </div>
              <div class="selector-icon">
                <i class="material-icons">arrow_drop_down</i>
              </div>
            </div>
          </template>
          <div class="menu-body">
            <div
              class="menu-item"
              v-for="(platform, index) in passPlatforms"
              :key="index"
              @click="onBrandChange(platform)"
            >
              {{ platform.brand }}
            </div>
          </div>
        </v-menu>
        <!-- country -->
        <v-menu
          transition="slide-y-transition"
          max-height="300"
          bottom
          right
          :disabled="!selectedBrand"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="search-selector" v-bind="attrs" v-on="on">
              <div class="selector-input">
                <span class="selector-label">{{ $t("country") }}</span>
                <template>
                  <div class="selector-value" v-if="selectedCountry">
                    {{ $t("countries." + selectedCountry.country) }}
                  </div>
                  <div class="selector-value empty-value" v-else>
                    {{ $t("select") }}
                  </div>
                </template>
              </div>
              <div class="selector-icon">
                <i class="material-icons">arrow_drop_down</i>
              </div>
            </div>
          </template>
          <div class="menu-body">
            <div
              class="menu-item"
              v-for="(country, index) in selectedBrandCountries"
              :key="index"
              @click="onCountryChange(country)"
            >
              {{ country.countryName }}
            </div>
          </div>
        </v-menu>
      </div>
      <!-- selected brand templates -->
      <template>
        <div
          class="selected-brand"
          v-if="selectedBrand && selectedCountry && isLoadedPlatform"
        >
          <div class="brand-title">{{ selectedPlatformData.brand }}</div>
          <div
            class="brand-description"
            v-html="selectedPlatformData.instruction.description"
          ></div>

          <div class="brand-codes" v-if="showCodeSection">
            <span class="code-subtitle">
              {{
                selectedPlatformData.access_type === "multi_use_code"
                  ? $t("your_personal_multi_use_code")
                  : $t("your_personal_single_use_code")
              }}
            </span>
            <div class="codes-list" v-if="availableCodes.length !== 0">
              <div
                class="code-line"
                v-for="(code, index) in availableCodes"
                :key="index"
                @click="copyCode(code.access_code)"
              >
                <template v-if="code.access_code">
                  <div>{{ code.access_code }}</div>
                  <!-- <div
                    class="copy-btn"
                    v-if="index === availableCodes.length - 1"
                  >
                    <i class="material-icons">content_copy</i>
                  </div> -->
                </template>
                <div v-else class="code-pending">
                  <mem-button disabled :btn-type="'secondary-dark'">{{
                    $t("code_requested")
                  }}</mem-button>
                </div>
              </div>
            </div>
          </div>

          <div class="section-buttons">
            <template v-if="showCodeSection && !codeRequestBtn.isHidden">
              <mem-button
                class="request-code-btn"
                :btn-type="'secondary-dark'"
                :disabled="codeRequestBtn.isDisabled"
                :loading="isLoadedCode"
                @click="requestCode"
                >{{
                  codeRequestBtn.isDisabled
                    ? $t("code_requested")
                    : $t("request_a_new_code")
                }}</mem-button
              >
            </template>

            <mem-button @click="bookRace">{{ $t("book_a_race") }}</mem-button>
          </div>
        </div>
        <mem-empty-list v-else>
          {{ $t("upass_search_instruction_empty_state") }}
        </mem-empty-list>
      </template>
    </div>
    <!-- benefits section -->
    <div class="benefits-section">
      <div class="mem-subtitle">{{ $t("benefits") }}</div>
      <div class="benefits-grid">
        <!-- rare -->
        <div class="grid-item" v-if="isRarePass">
          <div class="item-image">
            <img
              src="@/assets/upass_benefits_rare_item_icon.png"
              height="74"
              width="74"
              alt="rare!!!"
            />
          </div>
          <div class="item-title">
            {{ $t("u_pass_benefits_rare_item_title") }}
          </div>
          <div class="item-description">
            {{ $t("u_pass_benefits_rare_item_description") }}
          </div>
        </div>

        <div
          class="grid-item"
          v-for="(benefit, index) in benefitsList"
          :key="index"
        >
          <div class="item-image">
            <img :src="benefit.icon" :alt="index" />
          </div>
          <div class="item-title">{{ $t(benefit.title) }}</div>
          <div class="item-description">{{ $t(benefit.description) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { find, orderBy, map } from "lodash";

import itemIcon1 from "@/assets/upass_benefits_item_icon_1.svg";
import itemIcon2 from "@/assets/upass_benefits_item_icon_2.svg";
import itemIcon3 from "@/assets/upass_benefits_item_icon_3.svg";
import itemIcon4 from "@/assets/upass_benefits_item_icon_4.svg";
import itemIcon5 from "@/assets/upass_benefits_item_icon_5.svg";
import itemIcon6 from "@/assets/upass_benefits_item_icon_6.svg";
import itemIcon7 from "@/assets/upass_benefits_item_icon_7.svg";

export default {
  name: "UnbreakablePassView",
  data: () => ({
    isLoaded: false,
    isLoadedPlatform: false,
    isLoadedCode: false,
    selectedBrand: null,
    selectedCountry: null,

    benefitsList: [
      {
        title: "u_pass_benefits_item_1_title",
        description: "u_pass_benefits_item_1_description",
        icon: itemIcon1,
      },
      {
        title: "u_pass_benefits_item_2_title",
        description: "u_pass_benefits_item_2_description",
        icon: itemIcon2,
      },
      {
        title: "u_pass_benefits_item_3_title",
        description: "u_pass_benefits_item_3_description",
        icon: itemIcon3,
      },
      {
        title: "u_pass_benefits_item_4_title",
        description: "u_pass_benefits_item_4_description",
        icon: itemIcon4,
      },
      {
        title: "u_pass_benefits_item_5_title",
        description: "u_pass_benefits_item_5_description",
        icon: itemIcon5,
      },
      {
        title: "u_pass_benefits_item_6_title",
        description: "u_pass_benefits_item_6_description",
        icon: itemIcon6,
      },
      {
        title: "u_pass_benefits_item_7_title",
        description: "u_pass_benefits_item_7_description",
        icon: itemIcon7,
      },
    ],
  }),
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
    "mem-empty-list": () => import("@/components/EmptyList.vue"),
  },
  computed: {
    ...mapGetters([
      "passPlatforms",
      "selectedPlatformData",
      "userData",
      "isLoadedStatus",
    ]),
    selectedBrandCountries() {
      let brand = find(this.passPlatforms, { brand: this.selectedBrand });
      if (!brand) return;

      let countriesList = map(brand.countries, (country) => {
        let { country: countryCode } = country;
        return { ...country, countryName: this.$t(`countries.${countryCode}`) };
      });

      return orderBy(countriesList, ["countryName"], ["asc"]);
    },
    showCodeSection() {
      let { access_type } = this.selectedPlatformData;
      if (access_type === "multi_use_code" || access_type === "single_use_code")
        return true;
      return false;
    },
    codeRequestBtn() {
      let { access_type, access_requests } = this.selectedPlatformData;
      let code = access_requests?.[0];

      return {
        isDisabled:
          access_type === "multi_use_code" && code && !code.access_code,
        isHidden: access_type === "multi_use_code" && code && code.access_code,
      };
    },
    isRarePass() {
      let { upass } = this.userData;
      if (!upass) return;
      // return true;
      return upass.type === "rare";
    },
    availableCodes() {
      let { access_requests, access_type } = this.selectedPlatformData;
      if (
        access_type === "multi_use_code" &&
        access_requests[0] &&
        !access_requests[0].access_code
      )
        return [];

      return access_requests;
    },
  },
  watch: {
    "isLoadedStatus.userData": {
      handler(isLoaded) {
        if (!isLoaded) return;
        let { upass } = this.userData;

        if (!upass) return this.$router.push({ name: "home" });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      "getPassPlatforms",
      "getPassPlatformDetails",
      "sendPassAccessRequest",
    ]),
    onBrandChange(data) {
      this.selectedBrand = data.brand;
      this.selectedCountry = null;
      this.isLoadedPlatform = false;
    },
    async onCountryChange(country) {
      this.isLoadedPlatform = false;
      this.selectedCountry = country;

      try {
        await this.getPassPlatformDetails({
          platformId: this.selectedCountry.id,
          lang: this.userData.language,
        });
        this.isLoadedPlatform = true;
      } catch (error) {
        console.log(error);
      }
    },
    bookRace() {
      if (!this.selectedPlatformData.far_url) return;

      window.location = this.selectedPlatformData.far_url;
    },
    async requestCode() {
      this.isLoadedCode = true;
      let platformId = this.selectedPlatformData.id;
      try {
        await this.sendPassAccessRequest({
          platformId,
          lang: this.userData.language,
        });
        this.isLoadedCode = false;
      } catch (error) {
        console.log(error);
        this.isLoadedCode = false;
      }
    },
    async copyCode(code) {
      if (!code) return;
      try {
        let result = await navigator.clipboard.writeText(code);
        console.log(result);
      } catch (error) {
        console.log(error, "ERRR");
      }
    },
  },
  async mounted() {
    try {
      await this.getPassPlatforms();
      this.isLoaded = true;
    } catch (error) {
      console.log(error, "");
    }
  },
};
</script>
<style lang="scss" scoped>
.menu-body {
  background-color: #121212;
  border-radius: 4px;

  padding: 14px 4px;
  .menu-item {
    padding: 12px 16px;
    @include cursorPointer;
  }
  .menu-item:hover {
    background-color: #3e3e3e;
  }
}
.unbreakable-pass-view {
  max-width: 670px;
  padding-bottom: 80px;
  @media screen and (max-width: $mobile) {
    padding-right: 20px;
    padding-left: 20px;
  }
  .mem-subtitle {
    @include elementMarginBottom("S");
    @media screen and (max-width: $mobile) {
      padding-left: 0;
    }
  }
  .pass-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
    margin-top: 20px;
    margin-bottom: 28px;
    .back-button {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase;
      font-weight: 700;
      height: 44px;
      @include cursorPointer;
      @media screen and (max-width: $mobile) {
        display: none;
      }
    }
    .background-gradient {
      height: 445px;
      width: 445px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(251, 84, 30, 0.3) 0%,
        rgba(251, 84, 30, 0) 100%
      );
      @media screen and (max-width: $mobile) {
        width: 310px;
        height: 310px;
      }
    }
    .regular-image,
    .rare-image,
    .image-title {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
    .regular-image {
      height: 365px;
      width: 314px;
      @media screen and (max-width: $mobile) {
        width: 218px;
        height: 254px;
      }
    }
    .rare-image {
      width: 300px;
      height: 290px;
      top: 24px;
      @media screen and (max-width: $mobile) {
        width: 194px;
        height: 188px;
        top: 44px;
      }
    }
    .image-title {
      height: 210px;
      width: 444px;
      bottom: 7px;
      @media screen and (max-width: $mobile) {
        width: 260px;
        height: 122px;
      }
    }
  }
  .search-section {
    padding-top: 36px;
    margin-bottom: 40px;
    border-top: 1px solid #333333;
    @media screen and (max-width: $mobile) {
      border-top: none;
      padding-top: 0;
      margin-top: 40px;
      margin-bottom: 50px;
    }
    .search-selectors {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      margin-bottom: 15px;

      @media screen and (max-width: $mobile) {
        grid-template-columns: 1fr;
        gap: 12px;
      }
      .search-selector {
        height: 75px;
        background-color: #121212;
        padding-top: 18px;
        padding-right: 12px;
        padding-bottom: 18px;
        padding-left: 24px;
        display: grid;
        grid-template-columns: 1fr max-content;
        .selector-input {
          display: flex;
          flex-direction: column;
          min-width: 0;
          .selector-label {
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            letter-spacing: 0.02em;

            text-transform: uppercase;
            color: #9f9f9f;
          }
          .selector-value {
            font-size: 18px;
            line-height: 20xp;
            font-weight: 700;
            text-transform: capitalize;
            color: #ffffff;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .empty-value {
            color: #5a5a5a;
          }
        }
        .selector-icon {
          display: flex;
          flex-direction: column;

          justify-content: center;
          i {
          }
        }
      }
    }
    .selected-brand {
      background-color: #121212;
      padding: 32px 24px;
      @media screen and (max-width: $mobile) {
        padding: 30px 20px;
      }
      .brand-title {
        font-size: 20px;
        line-height: 25px;
        font-weight: 700;
        margin-bottom: 12px;
        @media screen and (max-width: $mobile) {
          margin-bottom: 10px;
        }
      }
      .brand-description {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
        @media screen and (max-width: $mobile) {
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 20px;
        }
      }
      .brand-codes {
        margin-top: 24px;
        margin-bottom: 12px;
        .code-subtitle {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
        }
        .codes-list {
          margin-bottom: 20px;
          .code-line {
            height: 36px;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 22px;
            line-height: 27px;
            font-weight: 600;
            letter-spacing: 0.4em;
            text-transform: uppercase;
            margin-top: 6px;
            @include cursorPointer;
            @media screen and (max-width: $mobile) {
              font-size: 18px;
              line-height: 22px;
            }
            // .copy-btn {
            //   height: 36px;
            //   width: 36px;
            //   border: 1px solid #363636;
            //   border-radius: 30px;
            //   color: #ffffff;

            //   display: flex;
            //   flex-direction: column;
            //   align-items: center;
            //   justify-content: center;
            //   i {
            //     font-size: 18px;
            //   }
            // }
            .code-pending {
              button {
                height: 35px;
                font-size: 12px;
                line-height: 15px;
                font-weight: 700;
                letter-spacing: 0.04em;
                margin-bottom: 4px;
              }
            }
          }
        }
      }
      .section-buttons {
        display: flex;
        flex-direction: row;
        @media screen and (max-width: $mobile) {
          flex-direction: column;
          align-items: flex-start;
        }
        button {
          height: 35px;
          font-size: 12px;
          line-height: 15px;
          font-weight: 700;
          letter-spacing: 0.04em;
        }
        .request-code-btn {
          margin-right: 10px;
          @media screen and (max-width: $mobile) {
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .benefits-section {
    .section-title {
    }
    .benefits-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
      @media screen and (max-width: $mobile) {
        grid-template-columns: 1fr;
        gap: 12px;
      }

      .grid-item {
        background-color: #121212;
        padding: 0 14px 20px 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .item-image {
          height: 132px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          margin-bottom: 24px;
        }
        .item-title {
          font-size: 18px;
          font-weight: 700;
          line-height: 25px;
          margin-bottom: 8px;
        }
        .item-description {
          font-size: 12px;
          line-height: 18px;
          max-width: 270px;
        }
      }
    }
  }
}
</style>